import React, { forwardRef } from 'react';
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';

import {
  BackgroundBody,
  OutboundUiModuleBody,
  OutboundUiModuleBodySection2ProviderLogoLogoBody,
  OutboundUiModuleBodySection7ErrorMessageBody
} from '../../../../../API';
import { FormBody, FormField } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { withStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../../../hooks';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import generateId from '../../../../../utils/generateId';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import FoldableSection from '../../../../shared/FoldableSection';
import { intersection } from 'lodash';
import { useRecoilValue } from 'recoil';
import { withLayoutErrors } from '../../../../../state/Layouts';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { Sortable } from '../../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';
import IconButton from '../../../../shared/IconButton';
import Button from '../../../../shared/Button';

type OutboundProps = keyof OutboundUiModuleBody;

type BackgroundFieldProp = 'section1Background' | 'section5Background' | 'section10Background';

interface BackgroundFieldProps {
  prop: BackgroundFieldProp;
  name: keyof BackgroundBody;
  required?: boolean;
  'data-testid'?: string;
}

interface OutboundUiModuleBodySection2ProviderLogoLogoBodyProps {
  name: keyof OutboundUiModuleBodySection2ProviderLogoLogoBody;
  required?: boolean;
  'data-testid'?: string;
  assetType: AssetTypesEnum;
  position: number;
}

const CustomFoldableSection = withStyles(FoldableSection, (theme) => ({
  root: {
    ['&.MuiAccordion-root']: {
      backgroundColor: theme.palette.background.default
    }
  },
  region: {
    ['& .MuiAccordionDetails-root']: {
      padding: theme.spacing(2)
    }
  }
}));

export const testIds = {
  formBody: 'outbound.form-body',
  providerLogo: 'outbound.provider-logo',
  providerLogoRemoveButton: 'outbound.provider-logo-remove-button',
  providerLogoAddButton: 'outbound.provider-logo-add-button',
  errorMessage: 'outbound.error-message',
  errorMessageRemoveButton: 'outbound.error-message-remove-button',
  errorMessageAddButton: 'outbound.error-message-add-button',
  formField: (
    name: keyof OutboundUiModuleBody | keyof BackgroundBody | keyof OutboundUiModuleBodySection2ProviderLogoLogoBody
  ): string => `outbound.form-field.${name}`
};

const OutboundForm = forwardRef<UIModuleTypeForm<OutboundUiModuleBody>>(() => {
  const { t } = useLocales();
  const { formControlColor, theme } = useTheme();
  const { control, watch } = useFormContext<OutboundUiModuleBody>();
  const layoutErrors = useRecoilValue(withLayoutErrors);

  const moduleId = watch('moduleId');

  const headerColor = (required: string[]) =>
    intersection(layoutErrors, required).length
      ? {
          color: theme.palette.error.main
        }
      : {};

  const {
    append: appendProviderLogo,
    remove: removeProviderLogo,
    fields: fieldsProviderLogo,
    replace: replaceProviderLogo
  } = useFieldArray({ control, name: 'section2ProviderLogo' });

  const {
    append: appendErrorMessage,
    remove: removeErrorMessage,
    fields: fieldsErrorMessage,
    replace: replaceErrorMessage
  } = useFieldArray({ control, name: 'section7ErrorMessages' });

  const AssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: OutboundProps;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            <AssetBrowser
              label={isRequired(label)}
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={testIds.formField(name)}
              hasError={!!error}
              onChange={onChange}
            />
          </FormField>
        )}
      />
    );
  };

  const renderAssetPicker = (
    name: keyof OutboundUiModuleBodySection2ProviderLogoLogoBody,
    assetType: AssetTypesEnum,
    moduleId: string,
    required: boolean,
    value: string,
    onChange: (event: string | React.ChangeEvent<Element>) => void,
    error: FieldError | undefined
  ) => (
    <FormField>
      <AssetBrowser
        label={markAsRequired(t(`layouts.outbound_form.fields.${name}`), required)}
        assetId={moduleId}
        assetType={assetType}
        value={value}
        data-testid={testIds.formField(name)}
        hasError={!!error}
        onChange={onChange}
      />
    </FormField>
  );

  const AssetPickerProviderLogo = ({
    name,
    assetType,
    position,
    required = false
  }: OutboundUiModuleBodySection2ProviderLogoLogoBodyProps): React.ReactElement => (
    <Controller
      control={control}
      name={`section2ProviderLogo.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const BackgroundAssetPicker = ({
    prop,
    name,
    assetType,
    required = false
  }: BackgroundFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={`${prop}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.outbound_form.fields.background.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const BackgroundField = ({ prop, section }: { prop: BackgroundFieldProp; section: number }) => {
    function getAssetType(backgroundVariation: string): AssetTypesEnum {
      const key = `section${section}${backgroundVariation}` as keyof typeof AssetTypes.outbound;
      return AssetTypes.outbound[key];
    }

    return (
      <>
        <Typography sx={{ margin: theme.spacing(4, 0), fontWeight: 'semibold' }} variant="body2">
          {t('layouts.outbound_form.headers.background')}
        </Typography>
        <Controller
          control={control}
          name={`${prop}.id`}
          defaultValue={generateId()}
          render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="portraitFillImage"
          assetType={getAssetType('BackgroundPortraitFillImage')}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="landscapeFillImage"
          assetType={getAssetType('BackgroundLandscapeFillImage')}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="mobileFillImage"
          assetType={getAssetType('BackgroundMobileFillImage')}
        />
        <BackgroundAssetPicker prop={prop} name="ctvFillImage" assetType={getAssetType('BackgroundCtvFillImage')} />
        <BackgroundAssetPicker
          prop={prop}
          name="desktopFillImage"
          assetType={getAssetType('BackgroundDesktopFillImage')}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="tabletFillImage"
          assetType={getAssetType('BackgroundTabletFillImage')}
        />
      </>
    );
  };

  const onAddProviderLogo = () => {
    appendProviderLogo({
      id: generateId(),
      providerLogo: ''
    });
  };

  const onAddErrorMessage = () => {
    appendErrorMessage({
      id: generateId(),
      section7Header: '',
      section7SubHeader: '',
      section7Body: '',
      section7Cta1: '',
      section7Cta2: '',
      section7Cta3: ''
    });
  };

  const LokaliseField = (name: keyof OutboundUiModuleBody, required = false): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.outbound_form.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseErrorMessageField = (
    name: keyof OutboundUiModuleBodySection7ErrorMessageBody,
    position = 0
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`section7ErrorMessages.${position}.${name}` as keyof OutboundUiModuleBody}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.outbound_form.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section1')}
          </Typography>
        }
        expanded
        disableGutters
      >
        <BackgroundField prop="section1Background" section={1} />
        <Box>
          <AssetPicker
            name="section1Logo"
            label={t('layouts.outbound_form.fields.section1Logo')}
            assetType={AssetTypes.outbound.section1Logo}
          />
        </Box>
        <Box>
          {LokaliseField('section1HeaderGeneric')}
          {LokaliseField('section1HeaderWithProviderName')}
          {LokaliseField('section1SubHeader')}
          {LokaliseField('section1CtaGeneric')}
          {LokaliseField('section1CtaProviderName')}
          {LokaliseField('section1CtaSignIn')}
          {LokaliseField('section1CtaHome')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section2')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>{LokaliseField('section2Header')}</Box>
        <Box>
          <Sortable
            list={fieldsProviderLogo}
            setList={replaceProviderLogo}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsProviderLogo.map((providerLogo, i) => (
              <Stack
                key={providerLogo.id}
                data-testid={testIds.providerLogo}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                <AssetPickerProviderLogo
                  name="providerLogo"
                  assetType={AssetTypes.outbound.section2ProviderLogo}
                  position={i}
                  required
                />
                <IconButton
                  title={t('layouts.outbound_form.actions.remove_provider_logo')}
                  disabled={fieldsProviderLogo.length === 1}
                  onClick={() => removeProviderLogo(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.providerLogoRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddProviderLogo}
            data-testid={testIds.providerLogoAddButton}
          >
            {t('layouts.outbound_form.actions.add_provider_logo')}
          </Button>
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section3')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section3Header')}
          {LokaliseField('section3HeaderAlternative')}
          {LokaliseField('section3SubHeader')}
          {LokaliseField('section3SubHeaderAlternative')}
          {LokaliseField('section3MailHint')}
          {LokaliseField('section3PasswordHint')}
          {LokaliseField('section3LegalDisclaimer')}
          {LokaliseField('section3CtaCreateAccount')}
          {LokaliseField('section3CtaSkip')}
          {LokaliseField('section3CtaSignIn')}
          {LokaliseField('section3ErrorEmail')}
          {LokaliseField('section3ErrorPassword')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section4')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section4Header')}
          {LokaliseField('section4SubHeader')}
          {LokaliseField('section4Body')}
          {LokaliseField('section4CtaLinkAccount')}
          {LokaliseField('section4CtaCreateAccount')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section5')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          <BackgroundField prop="section5Background" section={5} />
        </Box>
        <Box>
          {LokaliseField('section5Header')}
          {LokaliseField('section5Body')}
          {LokaliseField('section5CtaWatchNow')}
          {LokaliseField('section5CtaDownloadApp')}
          {LokaliseField('section5CtaContinueBrowser')}
          {LokaliseField('section5CtaNotifications')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section6')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section6Header')}
          {LokaliseField('section6MainHint')}
          {LokaliseField('section6PasswordHint')}
          {LokaliseField('section6CtaSignIn')}
          {LokaliseField('section6CtaCreateAccount')}
          {LokaliseField('section6CtaForgotPassword')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section7')}
          </Typography>
        }
        expanded
        disableGutters
      >
        <Box>
          <Sortable
            list={fieldsErrorMessage}
            setList={replaceErrorMessage}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsErrorMessage.map((errorMessage, i) => (
              <Stack
                key={errorMessage.id}
                data-testid={testIds.errorMessage}
                gap={6}
                marginBottom={5}
                padding={2}
                direction="row"
                width="100%"
              >
                <Box width="100%">
                  {LokaliseErrorMessageField('section7Header', i)}
                  {LokaliseErrorMessageField('section7SubHeader', i)}
                  {LokaliseErrorMessageField('section7Body', i)}
                  {LokaliseErrorMessageField('section7Cta1', i)}
                  {LokaliseErrorMessageField('section7Cta2', i)}
                  {LokaliseErrorMessageField('section7Cta3', i)}
                </Box>
                <IconButton
                  title={t('layouts.outbound_form.actions.remove_error_message')}
                  disabled={fieldsErrorMessage.length === 1}
                  onClick={() => removeErrorMessage(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.errorMessageRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddErrorMessage}
            data-testid={testIds.errorMessageAddButton}
          >
            {t('layouts.outbound_form.actions.add_error_message')}
          </Button>
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section8')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section8HeaderRoku')}
          {LokaliseField('section8HeaderCtv')}
          {LokaliseField('section8Step1Roku')}
          {LokaliseField('section8Step1UrlRoku')}
          {LokaliseField('section8Step2Roku')}
          {LokaliseField('section8CodeLength')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section9')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section9Header')}
          {LokaliseField('section9Step1')}
          {LokaliseField('section9Step2')}
          {LokaliseField('section9Step3')}
          {LokaliseField('section9CtaActivate')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section10')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          <BackgroundField prop="section10Background" section={10} />
        </Box>
        <Box>
          {LokaliseField('section10Header')}
          {LokaliseField('section10Body')}
          {LokaliseField('section10CtaContinue')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section11')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section11Header')}
          {LokaliseField('section11CtaWatchNow')}
          {LokaliseField('section11ErrorMessage')}
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section12')}
          </Typography>
        }
        expanded={false}
        disableGutters
      >
        <Box>
          {LokaliseField('section12Header')}
          {LokaliseField('section12HeaderAvod')}
          {LokaliseField('section12Body')}
          {LokaliseField('section12BodyAvod')}
          {LokaliseField('section12CtaContinueWeb')}
          {LokaliseField('section12CtaDownloadApp')}
        </Box>
      </CustomFoldableSection>
    </FormBody>
  );
});

OutboundForm.displayName = 'OutboundForm';

export default OutboundForm;
